import { useEffect, useState } from "react";
import Header from "../components/Header";
import whatsapp from "../assets/icons/whatsapp.svg";

const SuccessPage = ({ applicationId, company, consentUrl }) => {
  //const [countdown, setCountdown] = useState(10);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCountdown((prev) => prev - 1);
  //   }, 1000);

  //   const timeout = setTimeout(() => {
  //     window.location.href = consentUrl; // Redirect to the external URL
  //   }, countdown * 1000);

  //   return () => {
  //     clearInterval(interval); // Cleanup interval on component unmount
  //     clearTimeout(timeout); // Cleanup timeout on component unmount
  //   };
  // }, [consentUrl, countdown]);

  return (
    <>
      <Header hidePartnerLogo={true} />
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Your application has been successfully registered with us
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">
          Your Application Number
        </div>
        <div className="text-base font-sans text-[#404040] font-medium">
          {applicationId || ""}
        </div>
      </div>
      {company !== "moove" ? (
        <>
          {/* <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            As the next step, we will initiate the Aadhaar Digilocker process to securely verify your details. This will begin automatically in the next{" "}
            <span className="text-[#4f06fe]">{countdown} second{countdown > 1 ? "s" : ""}</span>.
          </div> */}
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            You will receive a message on your registered{" "}
            <img
              src={whatsapp}
              alt="WhatsApp Icon"
              style={{
                width: "20px",
                height: "20px",
                display: "inline-block",
                verticalAlign: "middle",
                margin: "0 5px",
              }}
            />
            WhatsApp number shortly to continue with the application process seamlessly.
          </div>
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            Thank you for choosing Sarvam!
          </div>
        </>
      ) : (
        <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
          Please refer to the dashboard provided by us for regular updates in{" "}
          {/* <span className="text-[#4f06fe]">{countdown} second{countdown > 1 ? "s" : ""}</span>. */}
        </div>
      )}
    </>
  );
};

export default SuccessPage;
