import React from "react";

const EWASuccessPage = ({ applicationId = "" }) => {
  // Determine the type of application based on the applicationId prefix
  let applicationType = "";
  if (applicationId.startsWith("ONBD")) {
    applicationType = "onboarding";
  } else if (applicationId.startsWith("DRDN")) {
    applicationType = "drawdown";
  }

  return (
    <>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        {applicationType === "onboarding"
          ? "Your onboarding application has been successfully registered with us"
          : applicationType === "drawdown"
          ? "Your drawdown request has been successfully registered with us"
          : "Your application has been successfully registered with us"}
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">
          {applicationType === "onboarding"
            ? "Your Onboarding Application Number"
            : applicationType === "drawdown"
            ? "Your Drawdown Request Number"
            : "Your Application Number"}
        </div>
        <div className="text-base font-sans text-[#404040] font-medium">
          {applicationId}
        </div>
      </div>
    </>
  );
};

export default EWASuccessPage;
