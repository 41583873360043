import { useState,useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Input from "./Input";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
  requestVideoPermissions
} from 'mic-check';
import {
  sendOtpApiCallHandler,
  verifyOtpApiCallHandler,
  verifyAadhaarOtpApiCallHandler,
  sendAadhaarOtpApiCallHandler
} from "../service/loanService";
import { toast } from "react-toastify";

let initialState = {
  mobile_number: "",
  otp: "",
  whatsapp_number: "",
};
const aadhaar_number_config = {
  label: "Enter Aadhaar number",
  type: "number",
  name: "aadhaar_number",
  id: "aadhaar_number",
  validation: {
    required: {
      value: true,
      message: "Aadhaar number is required",
    },
    maxLength: {
      value: 12,
      message: "Aadhaar number should be 12 digit",
    },
    minLength: {
      value: 12,
      message: "Aadhaar number should be 12 digit",
    },
  },
  page: "mobile-number-verification",
  inputInfoText:
    "An OTP will be sent to the mobile number linked to this Aadhaar card number.",
};
const mobile_number_config = {
  label: "Enter mobile number",
  type: "number",
  name: "mobile_number",
  id: "mobile_number",
  validation: {
    required: {
      value: true,
      message: "Mobile number is required",
    },
    maxLength: {
      value: 10,
      message: "Mobile number should be 10 digit",
    },
    minLength: {
      value: 10,
      message: "Mobile number should be 10 digit",
    },
  },
  page: "mobile-number-verification",
  inputInfoText:
    "After submission, we will be sending status updates on your whatsapp number",
};
const opt_config = {
  label: "Enter OTP",
  type: "number",
  name: "otp",
  id: "otp",
  validation: {
    required: {
      value: true,
      message: "OTP is required",
    },
    maxLength: {
      value: 6,
      message: "OTP should be 6 digit",
    },
    minLength: {
      value: 6,
      message: "OTP should be 6 digit",
    },
  },
  page: "mobile-number-verification",
  inputInfoText:
    "OTP will be sent on your mobile number linked with your aadhar number.",
};
const whatsapp_number_config = {
  label: "WhatsApp Number",
  type: "number",
  name: "whatsapp_number",
  id: "whatsapp_number",
  validation: {
    required: {
      value: true,
      message: "WhatsApp number is required",
    },
    maxLength: {
      value: 10,
      message: "WhatsApp number should be 10 digit",
    },
    page: "mobile-number-verification",
    minLength: {
      value: 10,
      message: "WhatsApp number should be 10 digit",
    },
  },
};

const MobileVerification = (props) => {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handlePermissionError = (err) => {
    const { type } = err;
    let message = "";

    if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
      message = "Camera access is denied by the system. Please enable camera access in your system settings.";
    } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
      message = "We will capture a live selfie as part of the application process, in compliance with RBI Guidelines. Camera access is mandatory for the same. Please provide camera access from you browser settings and click on Reload";
    } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
      message = "The camera is in use by another application. Close other applications and try again.";
    } else {
      //message = JSON.stringify(err) + "An unknown error occurred while accessing the camera. Please try again.";
      message = "We will capture a live selfie as part of the application process, in compliance with RBI Guidelines. Camera access is mandatory for the same. Please provide camera access from you browser settings and click on Reload";

    }

    setErrorMessage(message);
    setShowErrorModal(true);
  };
  useEffect(() => {
    requestMediaPermissions({ video: true })
      .then(() => {
        toast.success("Camera permissions granted!", {
          position: window.innerWidth < 768 ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: <div style={{ color: "#4e04ff", fontSize: "1.5rem" }}>✔</div>,
          style: {
            fontSize: window.innerWidth < 768 ? "0.9rem" : "1rem",
            padding: window.innerWidth < 768 ? "10px" : "15px",
            borderRadius: "8px",
            maxWidth: window.innerWidth < 768 ? "90%" : "400px",
            margin: window.innerWidth < 768 ? "0 auto" : "inherit",
          },
          progressStyle: {
            backgroundColor: "#4e04ff",
          },
        });
      })
      .catch((err) => {
        handlePermissionError(err);
      });
  }, []);
  const formRef = useForm(initialState);
  const [isLoading, setLoader] = useState(false);
  const {
    handleSubmit,
    unregister,
    formState: { isDirty, isValid },
  } = formRef;
  const [optSent, setOtpSend] = useState(false);
  const [otpRefId, setOtpRefId] = useState("");
  const [isThisMyWhatsAppNumber, setIsThisMyWhatsAppNumber] = useState(false);

  const sendOTPHandler = handleSubmit((data) => {
    // console.log('data form', data)
    const { aadhaar_number } = data;
    sendOtp(aadhaar_number);
  });

  const verifyOTPHandler = handleSubmit((data) => {
    const { aadhaar_number, otp, mobile_number } = data;
    verifyOtp(aadhaar_number, otp, mobile_number);
  });

  const requestCameraPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      toast.success("Camera permissions granted!");
      window.location.reload(); // Reload if permissions are granted
    } catch (error) {
      if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
        toast.error(
          "Camera access denied. Please check your browser settings to enable camera permissions."
        );
      } else {
        toast.error("An error occurred while accessing the camera.");
      }
    }
  };
  async function sendOtp(aadhaar_number) {
    try {
      setLoader(true);
      const result = await sendAadhaarOtpApiCallHandler(`${aadhaar_number}`);
      if (result.message === "OTP sent successfully" && result.status== "SUCCESS") {
        toast.success("OTP sent to your mobile number.");
        window.gtag("event", "send_otp", { aadhaar_number });
        setOtpSend(true);
        setOtpRefId(result.ref_id);
      }
      else{
        toast.error("Something went wrong! ", result?.message);
      }
    } catch (err) {
      toast.error("Something went wrong! Please try again later.");
    } finally {
      setLoader(false);
    }
  }

  async function verifyOtp(aadhaar_number, otp,mobile_number,whatsapp_number) {
    try {
      console.log("mobile no",mobile_number)
      //debugger;
      if (aadhaar_number && otp) {
        setLoader(true);
        const result = await verifyAadhaarOtpApiCallHandler(`${aadhaar_number}`, otp, otpRefId,mobile_number,whatsapp_number);
        // console.log('result aadhar api', result)
        if (result.status === "VALID") {
          if(result.pass_through ==  false){
            navigate("/existing-request", { state: { message: result.message } });
          }
          toast.success("OTP verified successfully.");
          window.gtag("event", "verify_otp_success", { aadhaar_number });
          props?.redirectToStep({ ...formRef?.getValues(), otp: "", aadhaarApiResponse: result });
        } else {
          toast.error("Incorrect OTP.", result.message);
          window.gtag("event", "verify_otp_failure", { aadhaar_number });
        }
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Verify Aadhaar number
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          {!optSent &&
          <>
           <Input {...aadhaar_number_config} />
           <Input {...mobile_number_config} />
          </>
           }
          {optSent && <Input {...opt_config} />}

          {optSent && (
            <span
              className="text-base text-[#4E05FF] font-sans my-5 border-[#5A786F] underline cursor-pointer"
              onClick={sendOTPHandler}
            >
              Resend OTP
            </span>
          )}

          {!optSent && (
            <div className="flex items-center my-6">
              <input
                checked={isThisMyWhatsAppNumber}
                id="default-checkbox"
                type="checkbox"
                value=""
                onChange={() => {
                  setIsThisMyWhatsAppNumber((prevState) => {
                    if (prevState) {
                      unregister(
                        whatsapp_number_config?.name,
                        whatsapp_number_config?.validation
                      );
                    }
                    return !prevState;
                  });
                }}
                className="w-4 h-4 text-[#5A786F] bg-gray-100 border-gray-300 rounded focus:ring-[5A786F] dark:focus:ring-[5A786F] dark:ring-offset-gray-800 focus:ring-2"
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-sm font-normal text-[#4D4D4D]"
              >
                This number is not my whatsapp number
              </label>
            </div>
          )}
          {!optSent && isThisMyWhatsAppNumber && (
            <Input {...whatsapp_number_config} />
          )}
          {!optSent && (
            <div className="my-7 mx-5 flex items-center justify-center">
              <button
                onClick={sendOTPHandler}
                disabled={!isDirty || !isValid}
                className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
              >
                Send OTP
              </button>
            </div>
          )}
          {optSent && (
            <div className="my-7 mx-5 flex items-center justify-center">
              <button
                onClick={verifyOTPHandler}
                disabled={!isDirty || !isValid}
                className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
              >
                Verify OTP
              </button>
            </div>
          )}
        </form>
      </FormProvider>
      {showErrorModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md md:max-w-lg lg:max-w-xl mx-4 md:mx-auto">
            <h2 className="text-lg md:text-xl font-bold mb-4">Camera Access Required</h2>
            <p className="text-gray-700 mb-6">{errorMessage}</p>
            <div className="flex flex-col md:flex-row justify-end gap-4">
            <button
            onClick={() => requestCameraPermissions()}
            className="bg-gray-500 text-white px-4 py-2 rounded w-full md:w-auto"
          >
            Reload
          </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MobileVerification;
