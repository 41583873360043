import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
} from "./apiService";

export async function sendOtpApiCallHandler(mobileNo) {
  try {
    const result = await postRequest("/loan-request", {
      type: "sendotp",
      mobile: mobileNo,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function verifyOtpApiCallHandler(mobileNo, otp) {
  try {
    const result = await postRequest("/loan-request", {
      type: "verifyotp",
      mobile: mobileNo,
      otp,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function sendAadhaarOtpApiCallHandler(aadhaar_number) {
  try {
    const result = await postRequest("/loan-request", {
      type: "aadhaar-send-otp",
      aadhaar_number: aadhaar_number,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && body.message === "OTP sent successfully") {
      return body;
    } else {
      return {
        status: "FAIL",
        message: body?.message || "Internal Error, please try again later"
      };
    }
  } catch (err) {
    throw err;
  }
}

export async function verifyAadhaarOtpApiCallHandler(aadhaar_number, otp, ref_id, mobile_number,whatsapp_number) {
  try {
    const result = await postRequest("/loan-request", {
      type: "aadhaar-verify-otp",
      aadhaar_number: aadhaar_number,
      otp: otp,
      ref_id: ref_id,
      mobile_number:mobile_number,
      whatsapp_number:whatsapp_number
    });
    // console.log('result response data', result.data, result)
    const { body, statusCode } = result?.data;
    if (statusCode === 200) {
      return body;
    } else {
      return {
        status: "FAIL",
        message: body?.message || "Internal Error, please try again later"
      };
    }
  } catch (err) {
    throw err;
  }
}

export async function fetchUserApiCallHandler(applicant_id) {
  try {
    const result = await postRequest("/loan-request", {
      type: "fetchuser",
      applicant_id: applicant_id,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function loanFormCheckStatusApiCallHandler(request) {
  try {
    const result = await postRequest("/form-precheck", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendLoanRequest(request) {
  try {
    const result = await postRequest("/loan-request", request);
    const { status, data } = result;
    if (status === 200 && data?.id) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendTwoWheelerLoanRequest(request) {
  try {
    const result = await postRequest("/two-wheeler-request", request);
    const { status, data } = result;
    if (status === 200 && data?.id) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendSelfieRequest(request) {
  try {
    const result = await postRequest("/aryaPhotoVerification", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}


export async function sendAdhaarConsent(request) {
  try {
    const result = await postRequest("/aadhaar-consent", request);
    return result.data;
  } catch (err) {
    throw err;
  }
}

export async function saveAadhaarLivenessResponseHandler(request) {
  try {
    const result = await postRequest("/aadhaarLivenessResponseHandler", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function hypervergeNotify(request) {
  try {
    const result = await postRequest("/hyperverge", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function ewaOnboardCheck(request) {
  try {
    const result = await postRequest("/ewa", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendEwaRequest(request) {
  try {
    const result = await postRequest("/ewa", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendDrawDownRequest(request) {
  try {
    const result = await postRequest("/ewa", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}
