import { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
import { sendDrawDownRequest } from "../service/loanService";

const Drawdown = ({ company, formFields, redirectToStep, onboarding_data, activeStep }) => {
    const formRef = useForm();
    const max_amount = onboarding_data?.max_amount || 5000;
    const onboarding_id = onboarding_data?.onboarding_id || "xbehbxhgeb";

    const drawdown_fields = formFields[company]?.filter(
        (field) => field?.page === "drawdown-details"
    ).map(field => ({
        ...field,
        validation: field.name === 'drawdown_amount' ? {
            ...field.validation,
            max: { value: max_amount, message: `Amount cannot exceed ₹${max_amount}` }
        } : field.validation
    }));

    const { handleSubmit, formState: { isDirty, isValid } } = formRef;

    const handleDrawdownSubmit = handleSubmit(async (data) => {
        const req_data = {
            "event_type": "post_drawdown",
            "onboarding_id": onboarding_id,
            "amount_requested": Number(data.drawdown_amount)
        };
        const post_drawdown_data = await sendDrawDownRequest(req_data);
        data.drawdown_application_no = post_drawdown_data.drawdown_application_no;
        redirectToStep(data, post_drawdown_data);
    });

    return (
        <div>
            <div className="flex flex-col items-center justify-center p-10 bg-white shadow-lg rounded-xl shadow-gray-400 max-w-md mx-auto">
                <h2 className="text-3xl font-bold text-[#4D4D4D] mb-3">Request Drawdown</h2>
                <p className="text-center text-[#666666] max-w-lg leading-relaxed">
                    Your onboarding is complete. You can now request a drawdown.
                </p>
                <p className="font-semibold text-lg mt-2 text-[#4E05FF]">Maximum Amount Available: ₹{max_amount}</p>
                
                <FormProvider {...formRef}>
                    <form onSubmit={(e) => e.preventDefault()} noValidate autoComplete="off" className="w-full mt-6 px-6 flex flex-col gap-4">
                        <div className="space-y-4 text-center w-full">
                            {drawdown_fields?.map((element, index) => (
                                <Fragment key={`${element.name}-${index}`}>
                                    <Input {...element} className="w-full" />
                                </Fragment>
                            ))}
                        </div>
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={handleDrawdownSubmit}
                                disabled={!isDirty || !isValid}
                                className="bg-[#4E05FF] text-white font-semibold text-lg py-3 px-6 rounded-lg transition-all duration-300 ease-in-out disabled:opacity-50 hover:scale-105 w-full"
                            >
                                Continue
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default Drawdown;