import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import { toast } from "react-toastify";
import { formFields } from "../constants/formFields";
import Header from "../components/Header";
import MobileVerification from "../components/MobileVerification";
import PersonalDetails from "../components/PersonalDetails";
import SelfieCapture from "../components/SelfieCapture";
import LoanDetails from "../components/LoanDetails";
import MobileNumberAlreadyRegistered from "../components/MobileNumberAlreadyRegistered";
import Terms from "../components/Terms";
import Loader from "../components/Loader";
import step_active from "../assets/icons/step_active.svg";
import step_completed from "../assets/icons/step_completed.svg";
import step_inactive from "../assets/icons/step_inactive.svg";
import {
    loanFormCheckStatusApiCallHandler,
    sendLoanRequest,
    sendSelfieRequest,
    sendAdhaarConsent,
    saveAadhaarLivenessResponseHandler,
    sendTwoWheelerLoanRequest,
    ewaOnboardCheck,
    sendEwaRequest
} from "../service/loanService";
import "../style.css";
import { useEffect } from "react";
import { twformFields } from "../constants/twformFields";
import { ewaformFields } from "../constants/ewaformFields";
import axios from "axios";
import DocUpload from "../components/DocUpload";
import RequestInProcess from "../components/RequestInProcess";
import Drawdown from "../components/Drawdown";
import EWASuccessPage from "../components/EWASuccessPage";

export default function EWAOnboarding() {
    const [isLoading, setLoader] = useState(false);
    const [isMounted, setMounted] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [loanData, setLoanData] = useState(null);
    const [onboarding_data, setOnboarding_data] = useState(null);
    const [applicationId, setApplicationId] = useState("");
    const [consentUrl, setconsentUrl] = useState("");
    const [preSelfieApplicationId, setpreSelfieApplicationId] = useState("");
    const [onboardingApplicationNo, setonboardingApplicationNo] = useState("");
    const [verifyPhoneData, setVerifyPhoneData] = useState(null);
    const [onboardingStatus, setOnboardingStatus] = useState(null);
    const [drawdownStatus, setDrawdownStatus] = useState(null);
    const search = useLocation().search;
    const company = new URLSearchParams(search).get("partner") || "";
    const emp_id = new URLSearchParams(search).get("emp_id") || "";
    let fieldsState = {};
    if (formFields && company) {
        formFields[company]?.forEach((field) => (fieldsState[field.name] = ""));
    }

    useEffect(() => {
        const _data = JSON.parse(localStorage.getItem(`${company}-${emp_id}`));
        if (_data?.loanData) {
            setLoanData(_data?.loanData);
        }
        if ((_data?.activeStep !== "" || _data?.activeStep !== undefined || _data?.activeStep !== null) && company == "chaloNetwork") {
            setActiveStep(1);
        }

        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            localStorage.setItem(
                `${company}-${emp_id}`,
                JSON.stringify({ loanData: loanData })
            );
        }
    }, [activeStep, loanData, company, emp_id]);

    const redirectToPersonalDetailPage = async (_data) => {
        try {
            setLoader(true);
            const _request = {
                mobile_no: `${_data?.mobile_number}`,
                aadhaar: `${_data?.aadhaar_number}`,
                "event_type": "check_onboarding",
                applicant_id: emp_id || "OPTIONAL",
                company: company || "",
                url: window.location.href
            };

            const result = await ewaOnboardCheck(_request);
            const { onboarding, drawdown } = result;

            setOnboardingStatus(onboarding);
            setDrawdownStatus(drawdown);
            if(result?.onboarding_id){
                setOnboarding_data({
                    onboarding_id:result?.onboarding_id,
                    max_amount:result?.max_amount || 30000
                })
            }

            setLoanData({
                ...loanData,
                ..._data,
            });

            setActiveStep(1);
        } catch (err) {
            toast.error("Something went wrong!");
        } finally {
            setLoader(false);
        }
    };

    const redirectToLoanDetailPage = async (_personalDetails) => {
        try {
            setLoader(true)
            const data = { ..._personalDetails, ...loanData, company: company };
            const save_twl_response = await sendTwoWheelerLoanRequest(data);
            // console.log('save response', save_twl_response)
            if (save_twl_response.request_id) {
                setApplicationId(save_twl_response.request_id)
            }
        } catch (error) {
            console.log('error', error)
            toast.error('Internal Server Error. Please try again later.')
        }
        finally {
            setLoader(false);
        }
    };

    const redirectToTermsDetailPage = (_loanData) => {
        setLoanData({
            ...loanData,
            ..._loanData,
        });
        setActiveStep(2);
    };

    const redirectToDocUpload = (_loanData) => {
        setLoanData({
            ...loanData,
            ..._loanData,
        });
        setActiveStep(3);
    };

    const saveSelfieData = async (url) => {
        debugger;
        try {
            setLoader(true);
            const selfieLambdaResponse = await sendSelfieRequest({
                "request_type": "lending-form-selfie",
                "img_url": url,
                "emp_id": preSelfieApplicationId || Math.random().toString(36).substring(2, 11),
                "aadhaarApiResponse": loanData.aadhaarApiResponse
            })
            
            console.log('selfieLambdaResponse', selfieLambdaResponse)
            debugger;
            if (preSelfieApplicationId && selfieLambdaResponse.body.approved) {
                setApplicationId(preSelfieApplicationId);
                const saveAadhaarLivenessResponse = await saveAadhaarLivenessResponseHandler(
                    {
                        "img_url": url,
                        "emp_id": preSelfieApplicationId,
                        "aadhaarApiResponse": loanData.aadhaarApiResponse,
                        "response_face_liveness": selfieLambdaResponse.body.response_face_liveness,
                    }
                )

            }
            else {
                setApplicationId(Math.random().toString(36).substring(2, 11));
                // toast.error("Something went wrong!");
            }
            debugger;
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false);
        }
        // setActiveStep(4);
    };

    const saveLoanDetails = async (documentUploads) => {
        try {
            let data = {
                ...loanData,
                ...documentUploads,
                "event_type": "post_onboarding",
                "applicant_id": emp_id
            };
            setLoader(true);
            const result = await sendEwaRequest(data);
            const { status, id,onboarding_application_no,employee_id } = result;
            if (status === "success") {
                window.gtag("event", "submission_success", { employee_id: emp_id });
                setActiveStep(4);
                setApplicationId(id);
                setonboardingApplicationNo(onboarding_application_no)
                localStorage.removeItem(`${company}-${emp_id}`);
            } else {
                window.gtag("event", "submission_error", { employee_id: emp_id });
            }
            let consentResponse = await sendAdhaarConsent({ "request_type": "consent", ewa_onboarding_id: id,emp_id:employee_id })
            console.log("consent response", consentResponse)
            // if (consentResponse.success) {
            //     setconsentUrl(consentResponse.url)
            // }

        } catch (err) {
            toast.error("Something went wrong!");
        } finally {
            setLoader(false);
        }
    };

    const goToHomeScreen = () => {
        setVerifyPhoneData(null);
        setLoanData(null);
    };

    const redirectToEWASuccessPage = (data) =>{
        const drawdown_id = data.drawdown_application_no 
        setApplicationId(drawdown_id)
        setonboardingApplicationNo(drawdown_id)
    }

    const renderLoadForm = () => {
        // Handle initial mobile verification step
        if (activeStep === 0) {
            return (
                <>
                    {verifyPhoneData ? (
                        <MobileNumberAlreadyRegistered
                            {...verifyPhoneData}
                            goToHomeScreen={goToHomeScreen}
                        />
                    ) : (
                        <MobileVerification
                            company={company}
                            redirectToStep={redirectToPersonalDetailPage}
                        />
                    )}
                </>
            );
        }

        // Handle different states based on API response using separate state variables
        // && drawdownStatus === "processing"
        if (onboardingStatus === "processing" || drawdownStatus === "processing") {
            return <RequestInProcess />;
        }

        if (onboardingStatus === "complete" && drawdownStatus === "no_request") {
            return <Drawdown
                company={company}
                formFields={ewaformFields}
                redirectToStep={redirectToEWASuccessPage}
                onboarding_data={onboarding_data}
            />;
        }

        // Regular flow for new requests
        switch (activeStep) {
            case 1:
                return (
                    <PersonalDetails
                        company={company}
                        formFields={ewaformFields}
                        loanData={loanData}
                        redirectToStep={redirectToTermsDetailPage}
                    />
                );
            case 2:
                return (
                    <Terms
                        company={company}
                        formFields={formFields}
                        redirectToStep={redirectToDocUpload}
                    />
                );
            case 3:
                return (
                    <DocUpload
                        company={company}
                        formFields={formFields}
                        loanData={loanData}
                        redirectToStep={saveLoanDetails}
                    />
                );
            //   case 4:
            //     return (
            //       <SelfieCapture
            //         company={company}
            //         // formFields={formFields}
            //         redirectToStep={saveSelfieData}
            //       />
            //     );
            default:
                return null;
        }
    };
    const getStepImg = (_fromStep) => {
        if (activeStep === _fromStep) {
            return step_active;
        } else if (activeStep > _fromStep) {
            return step_completed;
        } else if (activeStep < _fromStep) {
            return step_inactive;
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Header />
            {!applicationId ? (
                <>
                    <Stepper activeStep={activeStep} className="!p-0 loan-stepper my-3">
                        <Step
                            label="Step 1"
                            className="custom-step"
                            style={{
                                backgroundImage: `URL(${getStepImg(0)})`,
                                backgroundSize: "cover",
                                backgroundColor: "unset",
                            }}
                        />
                        <Step
                            label="Step 2"
                            className="custom-step"
                            style={{
                                backgroundImage: `URL(${getStepImg(1)})`,
                                backgroundSize: "cover",
                                backgroundColor: "unset",
                            }}
                        />
                        <Step
                            label="Step 3"
                            className="custom-step"
                            style={{
                                backgroundImage: `URL(${getStepImg(2)})`,
                                backgroundSize: "cover",
                                backgroundColor: "unset",
                            }}
                        />
                        <Step
                            label="Step 4"
                            className="custom-step"
                            style={{
                                backgroundImage: `URL(${getStepImg(3)})`,
                                backgroundSize: "cover",
                                backgroundColor: "unset",
                            }}
                        />
                                    {/* <Step
                        label="Step 4"
                        className="custom-step"
                        style={{
                            backgroundImage: `URL(${getStepImg(4)})`,
                            backgroundSize: "cover",
                            backgroundColor: "unset",
                        }}
            /> */}
                    </Stepper>
                    {renderLoadForm()}
                </>
            ) : (
                <EWASuccessPage applicationId={onboardingApplicationNo} />
            )}
        </>
    );
}
