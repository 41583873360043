import { useLocation } from "react-router-dom";
import quess from "../assets/quesslogo.png";
import swiggy from "../assets/swiggy_logo.png";
import sis from "../assets/sis.png";
import pecopp from "../assets/pecopp.png";
import mmf from "../assets/mymobiforce.png";
import zypp from "../assets/zypp.svg";
import smartstaff from "../assets/smartstaff.svg";
import entitledNew from "../assets/entitled_new.png";
import sarvamlogo from "../assets/sarvam.svg";
import letstransport from "../assets/letstransport.png";
import intercity from "../assets/intercity.png";
import mobiGarage from "../assets/mobiGarage.svg";
import wify from "../assets/wify_logo.png";
import teaestate from "../assets/enablecap.png";
import chefkart from "../assets/chefkart.png"
import evtfleet from "../assets/everest.png"
import rydo from "../assets/rydo.png"
import logipe from "../assets/logiPe.png"
import chaloNetwork from "../assets/chalonetwork.png"
import moove from "../assets/moove.svg"
import makemygarden from "../assets/make-my-garden.jpeg"

export default function Header({ hidePartnerLogo }) {
  const search = useLocation().search;
  let company = new URLSearchParams(search).get("partner") || "";
  const getCompanyImgSrc = (_company) => {
    let _link = "";
    switch (_company) {
      case "quess":
        _link = quess;
        break;
      case "pecopp":
        _link = pecopp;
        break;
      case "myMobiForce":
        _link = mmf;
        break;
      case "zypp":
        _link = zypp;
        break;
      case "smartstaff":
        _link = smartstaff;
        break;
      case "chefkart":
        _link = chefkart;
        break;
      case "wify":
        _link = wify;
        break;
      case "swiggy":
        _link = swiggy;
        break;
      case "letstransport":
        _link = letstransport;
        break;
      case "mobiGarage":
        _link = mobiGarage;
        break;
      case "MarapurandJamindarguriTeaEstate":
        _link = teaestate;
        break;
      case "enableCap":
        _link = teaestate;
        break;
      case "everestfleet":
        _link = evtfleet;
        break;
      case "intrcity":
        _link = intercity;
          break;
      case "rydo":
        _link = rydo;
          break;
      case "logipe":
        _link = logipe;
          break;
      case "chaloNetwork":
        _link = chaloNetwork;
          break;
      case "moove":
        _link = moove;
          break;
      case "sis":
        _link = sis;
          break;
      case "makemygarden":
        _link = makemygarden;
          break;
      default:
        break;
    }
    return _link;
  };

  return (
    <div className="flex p-5 bg-white justify-between items-center">
      <div
        className="w-32 h-8 md:w-36 md:h-10 bg-transparent bg-no-repeat bg-left-top bg-contain"
        style={{
          backgroundImage: `url(${sarvamlogo})`,
        }}
      ></div>
      {!hidePartnerLogo && (
        <div
          className="w-32 h-8 md:w-36 md:h-10 bg-transparent bg-no-repeat bg-right-top bg-contain"
          style={{
            backgroundImage: `url(${getCompanyImgSrc(company || "quess")})`,
          }}
        ></div>
      )}
    </div>
  );
}
