import Header from "../components/Header";
import { useLocation } from "react-router-dom";

const DuplicateMessage = () => {
  const location = useLocation();
  const message = location.state?.message || "You have an active request with us already";

  return (
    <>
      <Header />
      <div className="flex items-center justify-center px-4 py-4 sm:py-6">
        <div className="bg-white shadow-lg rounded-2xl p-6 sm:p-8 max-w-md sm:max-w-lg md:max-w-xl w-full text-center border border-gray-200 mt-4 sm:mt-6">
          {/* Icon */}
          <div className="flex justify-center mb-4">
            <svg
              className="w-14 h-14 sm:w-16 sm:h-16 text-blue-600"
              fill="none"
              stroke="#4e05ff"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m0-4h.01M21 12.22C20.45 9.88 18.14 8 15.5 8H8.5C5.86 8 3.55 9.88 3 12.22M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>

          {/* Title */}
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
            Important
          </h2>

          {/* Message Content */}
          {/* <p className="text-gray-700 text-base sm:text-lg mt-3 px-2 sm:px-4 leading-relaxed">
            {message}
          </p> */}

          <p className="text-gray-700 text-base sm:text-lg mt-3 px-2 sm:px-4 leading-relaxed">
          {message}  
          </p>

          {/* Decorative Line */}
          <div className="mt-5 sm:mt-6 border-t border-gray-300"></div>

          {/* Footer Text */}
          <p className="text-sm text-gray-500 mt-3">
            For any issues, contact support.
          </p>
        </div>
      </div>
    </>
  );
};

export default DuplicateMessage;
