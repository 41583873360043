import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoanPage from "./pages/Loan";
import Bot from "./pages/Bot";
import AdhaarSuccessPage from "./pages/AdhaarSuccessPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TWLoanPage from "./pages/TWLoan";
import EWAOnboarding from "./pages/EWAOnboarding";
import DuplicateMessage from "./pages/DuplicateMessage";

function App() {
  return (
    <div className="relative min-h-full h-full max-h-full flex justify-center md:bg-[#e5e2ed] ">
      <div className="max-w-full w-full md:mx-[25%] md:bg-[#fff] md:rounded-sm md:drop-shadow-md md:py-0 md:px-2">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoanPage />} />
            <Route exact path="/twl" element={<TWLoanPage />} />
            <Route exact path="/ewa/onboarding" element={<EWAOnboarding />} />
            <Route exact path="/healthbot" element={<Bot />} />
            <Route exact path="/aadhaar-success" element={<AdhaarSuccessPage />} />
            <Route exact path="/existing-request" element={<DuplicateMessage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
